import * as React from "react"
import Layout from "../../components/layout"
import mb from "../../images/mb/mb.png"
import km from "../../images/mb/km.png"
import { StaticImage } from "gatsby-plugin-image"
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import screen1 from "../../images/mb/intro.png"
import screen2 from "../../images/mb/tips.png"
import screen3 from "../../images/mb/upload.png"
import screen4 from "../../images/mb/stories.png"

import Footer from '../../components/footer';
import Wrapper from '../../components/wrap';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const IndexPage = () => {
  return (
    <Layout navtype="light" title="MagicBook">
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true" offset={0}>
        <StaticImage
          placeholder="blurred"
          formats={["AUTO", "WEBP", "AVIF"]}
          src="../../images/mb/head.png"
          alt="MagicBook"
          className="hero-image"
        />
      </AnimationOnScroll>
      <Wrapper className="first">
        <svg width="27" alt="MagicBook" className="project-logo" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.53272 10.3151L7.13043 0.604004L9.42342 0.981845L7.82571 10.693L5.53272 10.3151Z" fill="#7E4485" className="mb" />
          <path d="M19.3801 6.37525L17.9287 8.19155L25.4792 14.2345L26.9305 12.4182L19.3801 6.37525Z" fill="#7E4485" className="mb"/>
          <path d="M19.3746 14.0086L17.8369 23.4894L20.1307 23.8621L21.6685 14.3812L19.3746 14.0086Z" fill="#7E4485" className="mb"/>
          <path d="M5.99354 21.4943L15.0503 18.1702L15.8505 20.3537L6.79368 23.6778L5.99354 21.4943Z" fill="#7E4485" className="mb"/>
          <path d="M1.87715 10.1446L0.360229 11.9064L7.76795 18.2943L9.28487 16.5325L1.87715 10.1446Z" fill="#7E4485" className="mb"/>
          <path d="M20.423 0.796122L11.3052 4.35012L12.1486 6.51723L21.2664 2.96323L20.423 0.796122Z" fill="#7E4485" className="mb"/>
        </svg>
      </Wrapper>
      <AnimationOnScroll animateIn="animate__wrapInUp" animateOnce="true">
      <Wrapper>
        <p>2019. Now the Magicbook is <a href="https://www.phototales.com/" target="_blank" rel="noreferrer">Phototales</a>
        <br/>
          Magicbook is a service for transforming digital photos from chaos into a beautiful photo book
        </p>
        <strong className="sub-title">My role and the team</strong>
        <h3>Improve design workflow between teams</h3>
        <p>When I first joined MagicBook, there were several independent development and design teams from 🇮🇳 India, 🇫🇷 France, and 🇨🇭 Switzerland. In my team, I worked alongside the UI and motion designers. I led the design process, prototyping and a joint effort to validate our work together.</p>

        <strong className="sub-title">Challenges and goals</strong>
        <h3>Design white label application based on current codebase</h3>
        <p>Our initial goal was to design an iOS App for the first enterprise client (Kodak), which would use the existing (cross-platform) codebase. Looks like low-hanging fruit, just follow brand guidelines…
        </p>
        <p>As we were at the beginning, I suggested using a single source of components for design. This approach allows us to change only the original atom components, so the combined components will be adjusted automatically. A lot of user flows were optimized by me to be more flexible for each brand.</p>
        <p>I can't show all the work aspects of the Kodak project, but workflow improvements were instrumental. Magiclabs signed their first full-scale enterprise customers and had a measurable impact on allowing the company to expand to additional markets.
        <br/>
        <AnchorLink to="/projects/magicbook/#results">View Results</AnchorLink>
        </p>

      </Wrapper>
      <div className="compare">
        <AnimationOnScroll animateIn="animate__slideInUp" animateOnce="true">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../images/mb/km_final.png"
            alt="KodakMagic Final"
          />
        </AnimationOnScroll>
      </div>
      <br />
      <AnimationOnScroll animateIn="animate__slideInUp" animateOnce="true">
        <ReactCompareSlider className="compare prototype"
          itemOne={<ReactCompareSliderImage src={mb} alt="MagicBook" />}
          itemTwo={<ReactCompareSliderImage src={km} alt="KodakMagic" />}
          />
      </AnimationOnScroll>
      <br/>
      <div className="screens-grid">
        <div className="col">
        <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
          <img src={screen1} alt="Kodak Magic Auth" className='mobile-screen' />
        </AnimationOnScroll>
        </div>
        <div className="col">
          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
            <img src={screen2} alt="Kodak Magic intro" className='mobile-screen' />
          </AnimationOnScroll>
        </div>
        <div className="col">
          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
            <img src={screen3} alt="Uploading" className='mobile-screen' />
          </AnimationOnScroll>
        </div>
        <div className="col">
          <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
            <img src={screen4} alt="Stories" className='mobile-screen' />
          </AnimationOnScroll>
        </div>
      </div>
      <Wrapper>
        <h3 id="results">Results</h3>
        <p>We prepared a high-fidelity prototype of the Kodak App for the first round of negotiations. Later, MagicLabs launched three new brands, first in different countries: Photogurus (🇺🇸 USA), MonAlbumPhoto (🇫🇷 France), CEWE (🇩🇰 Denmark), and whitelabel products. Later, they quickly rebranded MagicBook into PhotoTales.</p>
      </Wrapper>
      </AnimationOnScroll>
    <Footer />
    </Layout>
  )
}

export default IndexPage
